<template>
  <div v-loading='loading' class='indexbox'></div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      loading: true
    }
  },
  computed: {},
  watch: {},
  methods: {},
  created() {
    const path = this.$route.query.redirect
    this.$router.replace(path)
  },
}
</script>
